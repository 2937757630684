exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-a-propos-js": () => import("./../../../src/pages/a-propos.js" /* webpackChunkName: "component---src-pages-a-propos-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-templates-category-list-js": () => import("./../../../src/templates/categoryList.js" /* webpackChunkName: "component---src-templates-category-list-js" */),
  "component---src-templates-service-detail-js": () => import("./../../../src/templates/serviceDetail.js" /* webpackChunkName: "component---src-templates-service-detail-js" */),
  "component---src-templates-service-list-js": () => import("./../../../src/templates/serviceList.js" /* webpackChunkName: "component---src-templates-service-list-js" */)
}

